import React from 'react';
import { graphql } from 'gatsby';

import { Layout, VideoThumbnail } from '../components';

const VideoPageTemplate = ({ data, pageContext }) => {
    const { title } = pageContext;
    const video = data.markdownRemark.frontmatter.videos.find(video => video.title === title);
    return (
        <Layout title={ video.title }>
            <div className='py-4 lg:py-8'>
                <div className='w-full px-4 mb-4 lg:mb-8 text-2xl lg:text-4xl text-center text-primary font-light'>{ video.title }</div>
                <div className='w-full px-4 md:px-0 md:w-4/5 lg:w-3/5 mx-auto'>
                    <VideoThumbnail { ...video } playButtonColour='primary' className='shadow-xl' size='large'/>
                </div>
            </div>
        </Layout>
    );
};

export default VideoPageTemplate;

export const pageQuery = graphql`
    query VideoPageTemplateQuery ($templateKey: String!) {
        markdownRemark(frontmatter: { templateKey: { eq: $templateKey } }) {
            frontmatter {
                videos {
                    thumbnail {
                        urlRemainder
                        sourceURL
                        deliveryType
                        cloudinaryMedia
                        assetType
                    }
                    video {
                        urlRemainder
                        sourceURL
                        deliveryType
                        cloudinaryMedia
                        assetType
                    }
                    title
                }
            }
        }
    }
`;